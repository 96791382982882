<template>
    <div class="appLink">
        <img width="100%" id="getAppImage" src="./assets/line_getapp.png"/>
        <br/>
        <a class="apps"  target="_blank" rel="noopener" ><img id="appStoreLinks"  src="./assets/btn_android_lrg.png" /></a>
        <a class="apps"  target="_blank" rel="noopener" ><img id="appStoreLinks" src="./assets/btn_ios_lrg.png" /></a>
    </div>
</template>

<script>

export default {
  name: "appLink",
  props: {
  }
  
}
</script>

<style scoped>
@import "./assets/fonts/stylesheet.css";
    .appLink{
        outline: none;
        text-align: center;
        width:100%;
        padding-bottom: 6rem;
        padding-top: 4rem;
        /* margin-top:10vmax; */
    }
    #getAppImage{
        padding-bottom: 4vw;
    }
    #appStoreLinks{
        /* width:25%; */
    }
    .apps{
        
        padding-left: 2vw;
        padding-right:2vw;
        outline: none;
        cursor: pointer;

    }
    @media screen and (max-width:48em) {
       #appStoreLinks{
        width:35%;
    }
    }
</style>