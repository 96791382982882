<template>
    <div class="teamPage" >
       <h1>Our Dedicated Team</h1>
       <div class="team-members">
          <div class="team-member">
            <img src="./assets/bio_cw.png" @click="open('Claire Wright','CEO','(Founder)',2)"/>
            <div class="member-title">
            <h2>Claire Wright </h2>
            <p> CEO <br> (Founder) </p>
            <a class="read-more" @click="open('Claire Wright','CEO','(Founder)',2)" >Read More</a>
            </div>
         </div>
          <div class="team-member">
            <img src="./assets/bio_cd.png" @click="open('Charles Durham','Chief Technology Advisor','(Founding member)',3)"/>
            <div class="member-title">
            <h2> Charles Durham</h2>
            <p>Chief Technology Advisor <br> (Founding member)</p>
            <a class="read-more" @click="open('Charles Durham','Chief Technology Advisor','(Founding member)',3)" >Read More</a>
            </div>
         </div>
          <div class="team-member">
            <img src="./assets/bio_ee.png" @click="open('Eric Etkin','Technical Product Lead','(Founding member)',4)"/>
            <div class="member-title">
            <h2>Eric Etkin</h2>
            <p>Technical Product Lead <br> (Founding member)</p>
            <a class="read-more" @click="open('Eric Etkin','Technical Product Lead','(Founding member)',4)"> Read More</a>
            </div>
         </div>
          <div class="team-member">
            <img src="./assets/kim-owens.jpeg" style="border-radius:100%"  @click="open('Kim Owens','COO','(Founding member)',1)"/>
            <div class="member-title">
            <h2>Kim Owens </h2>
            <p>COO <br> (Founding member)</p>
            <a class="read-more" @click="open('Kim Owens','COO ','(Founding member)',1)"> Read More</a>
            </div>
          </div>
          <div class="team-member">
            <img src="./assets/matt-adell.png"  @click="open('Matthew Adell','CSO','(Founding member)',5)"/>
            <div class="member-title">
            <h2>Matthew Adell </h2>
            <p>CSO<br> (Founding member)</p> 
            <a class="read-more" @click="open('Matthew Adell','CSO','(Founding member)',5)"> Read More</a>
            </div>
          </div>
       </div>
       <modal :name="memName" :title="memTitle" :titlespec="memTitlespec" :member="memNum-1" v-if="modalState" @close="exit" />
    </div>
</template>
<script>
import modal from "./Modal"

export default {
  name: 'teamPage',
  components:{
    modal
  },
  
  data:function() {
    return{
      modalState: false,
      memNum: 1,
      memName: '',
      memTitle: '',
      memTitlespec: '',
    }
  },
  methods:{
    exit:function() {
      this.modalState = false;
      this.memNum = 0;
    },
    open:function(name, title, titlespec, member) {
      this.memName = name;
      this.memTitle = title;
      this.memTitlespec = titlespec;
      this.memNum = member;
      this.modalState = true;
    }
  }
}

</script>

<style scoped>
@import "./assets/fonts/stylesheet.css";
.teamPage {
  width: 100%;
  outline: none;
  text-align: center;
  margin: 3rem 0;
  font-family: 'HelveticaNeueCond';
  /* font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; font-weight:600; font-stretch:condensed; */
}
.square-profile-image {
  height: 236px;
  width: 236px;
  border-radius: 237px;
  object-fit: cover;
}
h2 {
  font-size:30px ;
  /* font-family: "HelveticaNeue", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; */
  /* font-weight:600;
  font-stretch:condensed; */
  color:#232323;
  margin-bottom: 0;
}

p {
  margin: .5rem 0;
  /* font-family: "HelveticaNeue", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; */
  font-weight:100;
}
.team-members{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}



img {
  cursor: pointer;
}

.team-member{
  width: 20%;
  align-items: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 0;
}

.team-member img{
  width: 50%;
}


.read-more{
  cursor: pointer;
  text-decoration: underline;
  color:#9400b7;
}
.member-title{
  align-content: center;
}

@media screen and (max-width: 1030px) {
  .team-members{
    flex-direction: column;
  }
  .team-member{
    width: 65%;
  }
}

</style>