<template>
    <div class="slider">
      <button class="leftArrow" @click="prev"><img src="./assets/btn_arrow_left_light.png"/></button> 
      <div class="textFlex">
         <p id="whiteText">{{quotes[currentNumber].testimony}}</p>
        <span id="blackText">{{quotes[currentNumber].name}}<br/>&#160;</span>
      </div>
      <button class="rightArrow" @click="next"><img src="./assets/btn_arrow_right_light.jpg"/></button>
    </div>
</template>

<script>

export default {
  name: "testimonySlider",
  components: {
      
  },
   data:function() {
    return {
      quotes: [
        {
          testimony: 'I always get lost at big events, but Buddy is a game changer!',
          name: 'Julie K., Cape May, NJ'
        },
        {
          testimony: 'I live in a canyon with limited cell reception and always worry about natural disasters, I feel so safe knowing that if all services were cut I have this app to communicate with the outside world.',
          name: 'Tyler S., Flagstaff, AZ'
        }
      ],
      currentNumber: 0,
    }
  },

    /* eslint-disable */
  methods: {
    next: function() {
      this.currentNumber = (this.currentNumber !== this.quotes.length - 1) ? this.currentNumber + 1 : 0;
    },
    prev: function() {
      this.currentNumber = (this.currentNumber !== 0) ? (this.currentNumber - 1) : (this.quotes.length - 1);
    },
  },
}
</script>

<style scoped>
@import "./assets/fonts/stylesheet.css";
  .slider {
    outline: none;
    overflow: hidden;
    background-size: 100% 100%;
    background-position: center;
    background-image:url("./assets/bg_quote.jpg");
    text-align: center;
    font-family: 'HelveticaNeueCond';
    /* font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; font-weight:600; font-stretch:condensed; */
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    height: 450px;
  }
  .leftArrow,.rightArrow {
    background-color:transparent;
    border:none;
    outline:0;
    cursor: pointer;
    padding:0;
  }
  .leftArrow {
    margin-left: 3rem;
  }
  .rightArrow {
    margin-right: 3rem;
  }
  #whiteText {
    color:white;
    font-size: 2rem;
    width:90%;
    margin:auto;
    margin-bottom:2rem;
  }
  #blackText {
    color:#232323;
    font-size: 1.5rem;
    width:100%;
    text-align: center;
    margin:auto;
    float:left;
  }
  .textFlex {
    padding: 5rem;
    margin: auto;
  }

@media screen and (min-width: 1540px) {
  .slider {
    max-width: 1540px;
    margin: auto;
  }
}

@media screen and (max-width: 1350px) {
  .slider {
    height: 375px;
  }
}

@media screen and (max-width: 1160px) {
  .slider {
    height: 325px;
  }
}

 @media screen and (max-width: 950px) {
   .slider {
      height: 275px;
    }
    .textFlex {
      padding: 0;
    }
    #whiteText {
      /* font-size: 18px; */
      margin-bottom: 1rem;
    }
    #blackText {
      /* font-size: 14px; */
    }
    .leftArrow,.rightArrow {
      transform: scale(0.5);
      margin: 0 !important;
    }
  }

  @media screen and (max-width: 815px) {
    #whiteText {
      font-size: 1.5rem;
    }

    #blackText {
      font-size: 1rem;
    }
  }
</style>