<template>
  <div @click="close" class="modal-backdrop">
    <div class="modal">
      <section class="modal-body">
        <slot name="body">
          <img class="square-profile-image" :src="require(`${members[member].img}`)" />
          <div class="modal-body-text">
            <h1>{{name}}</h1>
            <h2>{{title}}</h2>
            <h2>{{titlespec}}</h2>
          </div>
          <p>{{members[member].intro}}</p>
          <p>{{members[member].mid}}</p>
          <p>{{members[member].bottom}}</p>
        </slot>
       </section>
    </div>
  </div>
</template>

<script>

 /* eslint-disable */
export default {
  name: 'modal',
  data:function() {
    return {
      members: [
        {
          img: './assets/kim-owens.jpeg',
          intro: 'After a successful technology sales career in Silicon Valley, the birth of the internet pivoted Kim into launching her own digital marketing agency, Buzz Boulevard, serving clients in retail, technology, non-profit, higher education, entertainment, finance, and heath care.',
          mid: 'For over 20 years she has also reported on entertainment, tech, culture and the live events industry, publishing in-depth reports and presenting findings on event tech trends, connectivity, sponsorships, livestreaming, experiential, XR, and more.',
          bottom: "As a COO-for-hire at FestiFi, Kim increased and diversified the event IT company’s revenue streams, developed marketing strategies, and redesigned internal operations. Tapping her industry network, she cultivated  strategic partnerships and led hundreds of event WiFi deployments for clients including Lyft, Disney, Amazon, Cartier, and Land Rover.",
        },
        {
          img: './assets/bio_cw.png',
          intro: 'Claire Wright is an innovative entrepreneur with over 20 years experience who has dedicated her career to culture and development in the wellness and music industries.',
          mid: 'With an Audio Engineering background, Claire began her Music Industry career in the early 1990s working at influential record and publishing companies. With Dance Music being her main passion, Claire left to work on underground Dance projects with smaller independent labels and running various events across the globe celebrating Dance Music. Her sheer dedication and intention with the wellness and music markets drove her to scale an award-winning roll-out business model that launched multiple concepts across the UK, USA, Australia, and Europe.',
          bottom: 'Claire took the initiative to preserve and thrive the culture and prosperity of several non-profits in the music industry. This is where she identified a need for enhancing empowerment by connecting a community and Buddy was found.'
        },
        {
          img: './assets/bio_cd.png',
          intro: 'Charles Durham is one of the dynamic individuals who thrive in bringing innovative web, mobile and geospatial solutions.',
          mid: 'He spearheaded and served high-end IoT solutions to one of the Fortune 500 enterprises, Hertz Corporation. His utmost dedication to the development of the back-end server creating led to the success of their popular product, Hertz Neverlost devices. Providing advanced and integrating back-end systems and IoT is his forte. Proudly offered their expertise in the back-end system to develop platforms for Verizon to demonstrate New Smart City technology and advanced AI technology. Charles was proactively involved in the development of IoT integration with remote IoT locking devices and the end-user mobile applications. Charles, a trailblazer in the high-end technological areas, including Augmented Reality, Virtual Reality as well as AI.',
          bottom: 'Charles is a self-driven and highly dedicated individual who is paving the way in providing back-end systems and IoT solutions.'
        },
        {
          img: './assets/bio_ee.png',
          intro: 'An experienced project lead whose entrepreneurial instincts have acquired and managed talent in delivering highly successful technical software solutions to an impressive array of clients.  These clients include Fortune 500 companies like Intel, Verizon, Papa Johns, Weight Watchers and Ernst & Young.',
          mid: 'With an engineering background Eric Etkin is a powerful business driver whose clarity of vision have carried multiple companies through rapid and continuous growth.',
          bottom: 'Eric acquiring and managing talent to help drive success in delivering highly successful web, mobile and extended reality solutions to an impressive array of clients.  These clients include Fortune 500 companies like Intel, Verizon, Papa Johns, Weight Watchers and Ernst & Young.  Eric specializes in several key areas, including project management, architecting technical solutions, people and process improvement, business solutions, and communications.'
        },
        {
          img:'./assets/matt-adell.png',
          intro: "Mathew Adell is a seasoned music technology entrepreneur, entertainment executive, and startup founder. Matt’s early-adopter internet vision led to executive-level positions at Amazon, Radiowave, and MusicNow.",
          mid: "With a penchant for transforming business models and tech innovation, Matthew joined Napster as V.P. of Music Services, then Beatport as CEO , he built its valuation to $65+ million, orchestrating the sale of the company to deliver substantial shareholder value. Later, he founded MetaPop which was sold to Native Instruments.",
          bottom: "Under his leadership, Matthew has built companies and products for rapid growth and positioned them for acquisition by larger corporations while continuing his founder's journey."
        }
      ],
    }
  },    
  props:{
    member: Number,
    title: String,
    name: String,
    titlespec: String,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
@import "./assets/fonts/stylesheet.css";
p {
  font-size: 16px;
  margin-bottom: 0;
}
h1 {
  color: black;
  margin-top: 1rem;
}
h2 {
  font-size: 16px;
}

h2:first-of-type {
  margin-top: 2rem;
}

.square-profile-image {
  height: 236px;
  width: 236px;
  border-radius: 237px;
  object-fit: cover;
}

.modal-body-text {
  line-height: 10px;
  margin-bottom: 0 !important;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-height:90%;
  width:50%;
  overflow-y:auto;
  border-radius:25px;
  
}

.modal-header {
  display: flex;
}

.modal-header {
  color: black;
  justify-content: end;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px 10px;
  width:90%;
  margin:auto;
}

.btn-close {
  position:relative;
  top:0;
  right:0;
  left:90%;
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  color: black;
  background: transparent;
  
}
::-webkit-scrollbar {
  width: 10px;
  margin-left:30px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius:25px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
@media screen and (max-width:1024px){
  .modal {
    width:90%;
  }
}

</style>