<template>
  <div class="modal-backdrop">
    <div class="modal">
      <section class="modal-body">
        <slot name="body">
          <h1>Inquiring More Info</h1>
          <label>Name *</label>
          <input type="text" placeholder="Enter name..." v-model="form.name" required>
          <label>Email *</label>
          <input type="text" placeholder="Enter email..." v-model="form.email" required>
          <label>Company *</label>
          <input type="text" placeholder="Enter company..." v-model="form.company" required>
          <label>Position *</label>
          <input type="text" placeholder="Enter position..." v-model="form.position" required>
          <label>Message</label>
          <textarea placeholder="Enter message..." v-model="form.message"/>
          <small>{{error}}</small>
          <div class="buttons">
            <div class="cancel" @click="close">CANCEL</div>
            <div class="submit" @click="submit">SUBMIT</div>
          </div>
        </slot>
       </section>
    </div>
  </div>
</template>

<script>
 /* eslint-disable */
export default {
    name: 'more-info-modal',
    data:function() {
      return {
        form: {
          name: '',
          email: '',
          company: '',
          position: '',
          message: '',
        },
        error: ''
      }
    },    
    methods: {
      close() {
        this.$emit('close');
      },
      submit() {
        if (this.form.name === '' || this.form.email === '' || this.form.company === '' || this.form.position === '') {
          this.error = 'All form fields marked with an * must be filled out.';
        } else {
          window.location=`mailto:buddy@buddytech.net?subject=Buddy System Event Enquiry&body=${this.form.name}, ${this.form.email}, ${this.form.company}, ${this.form.position}, ${this.form.message}`;
          this.close();
        }
      }
    },
  };
</script>

<style>
@import "./assets/fonts/stylesheet.css";
p {
}
h1{
  margin-bottom: 1rem;
}

label {
  font-family: 'HelveticaNeueBold';
  color: black !important;
}

input, textarea {
  margin-bottom: 1rem;
  border: 1px solid #d1d1e0;
  border-radius: 10px;
  padding-left: 1rem;
  width: 95%;
  font-family: 'HelveticaNeueLight';
  font-size: 13px;
}

input {
  height: 30px;
}

textarea {
  padding-top: 1rem;
}

small {
  color: red;
  margin-bottom: 1rem;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cancel {
  margin-right: 10px;
}

.cancel, .submit {
  cursor: pointer;
  background-color: #9400b7;
  color: white;
  padding: .5rem 2rem;
  border-radius: 10px;
  font-family: 'HelveticaNeueCond';
}

.modal-body-text{
  line-height:10px;
  margin-bottom:3rem;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-height:90%;
  width:50%;
  min-width: 400px;
  overflow-y:auto;
  border-radius:25px;
}

.modal-header {
  display: flex;
}

.modal-header {
  color: black;
  justify-content: end;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 20px 10px;
  width: 90%;
  margin:auto;
}

.btn-close {
  position:relative;
  top:0;
  right:0;
  left:90%;
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  color: black;
  background: transparent;
}
::-webkit-scrollbar {
  width: 10px;
  margin-left:30px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius:25px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

</style>