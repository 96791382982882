import Vue from 'vue'
import App from './App.vue'
import vueScrollto from "vue-scrollto";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus,faMinus} from "@fortawesome/free-solid-svg-icons";
import { faFacebook,faInstagram,faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPlus);
library.add(faMinus);
library.add(faFacebook);
library.add(faInstagram);
library.add(faTwitter);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;


Vue.use(vueScrollto);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
