<template>
  <div id="app">
    <pageHeader/>
    <pageBody/>
    <a id="Contact"><pageFooter/></a>
  </div>
</template>

<script>
import pageHeader from "./components/Header.vue"
import pageBody from "./components/Body"
import pageFooter from "./components/Footer"

export default {
  name: "app",
  components: {
    pageHeader,
    pageBody,
    pageFooter
  }
}
</script>

<style>
@import "./assets/styles.css";
#app{
  margin:-8px !important;
  overflow-x: hidden;
  padding-bottom:0%;
}
body{
  margin-bottom:0 !important;
}
</style>
