<template>
    <div class="middle">
        <h1 >Stay connected.</h1>
        <h2> No WiFi, Cell Networks, Maps Required. With the latest technology and purpose Buddy empowers the community.</h2>
        <div class="info-section-middle">
            <iframe id="buddy-video"  class="info-video info-middle-section-item" src="https://www.youtube.com/embed/c0E3E1oGO64" frameborder="0"></iframe>
            <img id="mainImage" class="info-middle-section-item" src="./assets/appscrn_main.png"/>
        </div>
        
        <p >Friend locations display on the map. Use at the latest events for Event-driven points-of-interests (POI).</p>
    </div>
</template>

<script>

export default {
  name: "middle",
  components: {
  }
  
}
</script>

<style scoped>
@import "./assets/fonts/stylesheet.css";

    #buddy-video{
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: -10px;
        height: 380px !important;
    }

    .info-section-middle{
        padding: 100px;
        display: flex;
        justify-content: space-evenly;
        margin: auto;
    }

    .info-middle-section-item{
        align-self: flex-start;
        height: 400px !important;
        width: 300px !important;
        overflow: hidden;
    }

    .info-video{
        width: 500px !important;
    }

    .middle {
        outline: none;
        text-align: center;
        width: 100%;
        margin-top: 6.25rem;
    }
    h1 {
        width: 98%;
        margin-left: 1%;
        text-align: center;
        /* padding: 0 2rem; */
        margin-bottom: 0;
    }
    h2 {
        font-size: 25px;
        color: #232323;
        width: 60%;
        margin-left: 20%;
    }
    #mainImage {
        display: block;
        width:25%;
    }
    p {
        font-size: 18px;
        /* font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;  */
        /* font-weight: 300; */
        color: #232323;
        width: 40%;
        margin-left: 30%;
    }
@media screen and (max-width: 850px){

    #mainImage {
        width:50%;
    }
    p {
        /* font-size:1.9vmax ; */
    }
     .info-section-middle{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .info-middle-section-item{
        align-self: center;
        height: auto !important;
        width: auto !important;
        max-height: 500px;
        overflow: hidden;
    }
}

@media screen and (max-width: 700px) {
    h2 {
        width: 98%;
        margin-left: 1%;
    }

    .info-section-middle{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .info-middle-section-item{
        align-self: center;
        height: 400px !important;
        width: 300px !important;
        overflow: hidden;
    }
}
</style>