<template>
  <div class="pageFooter" >
    <br/>
      <h1>Get in the know.</h1>
      <h2> Stay up-to-date on the latest developments 
      <br/> with the Buddy apps, events and news.</h2>
        
      <form action="mailto:buddy@buddytech.net?subject=New Email Signed Up!" method="post" enctype="text/plain">
        <div class="emailDiv">
          <input  id="emailBar" type="email" placeholder="Enter your email address" name="New Email " required/>
          <button id="emailButton" type="submit">Sign Up</button>
        </div>
      </form>
      <br/>
      <br/>
      <img width="100%" height="1.1vw" id="whiteBar" src='./assets/line_white.png'/>
      <br/>
      <br/>
      <br/>
      <img src="./assets/logo_footer.png" />
      <br/>

      <a v-scroll-to="'#StayConnected'"><button class="tablinks" >Stay Connected </button></a>
      <a v-scroll-to="'#Experience'"><button class="tablinks" >Event Experience </button></a>
      <a v-scroll-to="'#Team'"><button class="tablinks" >Team </button></a>
      <a v-scroll-to="'#Protocol'"><button class="tablinks" >Protocol </button></a>
      <a v-scroll-to="'#FAQ'"><button class="tablinks" >FAQ </button></a>
        
      <div class="footer-social-links">
        <a href="https://www.facebook.com/buddysystems" target="_blank" ><font-awesome-icon :icon="['fab', 'facebook']" class="social-media-icon"/></a>
        <a  href="https://www.instagram.com/buddy.technology" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" class="social-media-icon"/></a>
        <a  href="https://twitter.com/buddy_systems" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']" class="social-media-icon"/></a>
      </div>
      <p id="copyright"><span>{{thisYear}} BUDDY LLC</span><span>info@buddytech.net</span><span>2934 Beverly Glen, Bel Air, CA 90077</span><span><a href="./PrivacyPolicy.pdf" target="_blank">Privacy Policy</a></span></p>
    </div>
</template>
<script>
import * as moment from 'moment';
export default {
  name: 'pageFooter',

  data:function() {
    return {
      thisYear: moment().year()
    }
  }
}

</script>

<style scoped>
@import "./assets/fonts/stylesheet.css";

.social-media-icon {
  color: white;
  margin: 0 2rem 2rem;
  width: 20px;
}

.fa-facebook,.fa-instagram,.fa-twitter {
  width: 25px;
  height: 25px;
}
.pageFooter {
  background-size: cover;
  background-position: center;
  background-image: url("./assets/bg_footer.jpg");
  text-align: center;
  width:100%;
  padding-bottom:0%;
}

h1 {
  color: white;
  font-size: 2rem;
  font-family: 'HelveticaNeueCond';
}

h2 {
  color: white;
  font-size: 1.5rem;
}

.emailDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#emailBar {
  background-color: white;
  border-radius: 20px;
  border: none;
  color: black;
  text-decoration: none;
  display: inline-block;
  padding-left: 30px;
  height: 40px;
  width: 250px;
  font-size: 18px !important;
  cursor: pointer;
  outline: none;
  margin-bottom: 0 !important;
  font-style: italic;
}

#emailBar::placeholder {
  color: black;
  font-size: 20px;
  text-align: left;
  padding-bottom: 1vw;
}

#emailButton{
  color: #9400b7;
  background-color: white !important;
  padding: .5rem 1rem;
  border-radius: 20px;
  height: 40px !important;
  width: 90px !important;
  border:none;
  cursor: pointer;
  background: none;
  outline: none;
  margin-left: 10px;
  font-size: 15px;
  align-items:center;
  font-family: 'HelveticaNeueCond';

  -webkit-box-shadow: 0px 5px 7px 2px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 5px 7px 2px rgba(0,0,0,0.5);
  box-shadow: 0px 5px 7px 2px rgba(0,0,0,0.5);
}

.tablinks {
  background-color: transparent;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 1vw 1vw;
  cursor: pointer;
  outline: none;
  font-family: 'HelveticaNeueLight';
}
#copyright {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgba(63, 63, 63, 0.325);
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  height: 50px;
  font-family: 'HelveticaNeueLight';
  bottom: 0;
  margin-bottom: 0;
}

#copyright a {
  text-decoration: none;
  color: white;
  margin-left: .25rem;
}

#copyright a:hover {
  text-decoration: underline;
}

#copyright span {
  padding: 0 .5rem;
  white-space: nowrap;
}

#copyright span:not(:last-of-type) {
  border-right: 1px solid white;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-device-width : 767px) {
  #emailBar {
    width: 225px !important;
    padding-left: .5rem !important;
  }
  #footerLogo {
    width:26%;
  }
  .tablinks {
    font-size: 12px;
  }
}

@media screen and (max-width:1023px) {
  h1 {
    font-size: 30px; 
  }

  h2 {
    font-size: 16px;
  }
}
</style>