<template>
    <div class="pageBody">
        <top/>
        <a id="StayConnected"><middle/></a>
        <a id="Experience"><experiencePage /></a>
        <!--a id="GetTheApp"><AppLink/></a>-->
        <testimonySlider/>
       <!-- <a id="StaySafe"><bottom/></a>-->
       <a id="Team"><teamPage/></a>
       <a id="Protocol"></a>
       <div class="protocol-sect">
        <div class="protocol-text">
          <h1>Protocol</h1>
          <p>BUDDY’s innovative technology delivers a communication network that empowers people to stay connected without cellular, data, or Wi-Fi network while offering unique opportunities for brands and content creators.</p>
          <p>BUDDY is committed to transforming the way people connect and experience the world at any time and any place while impacting positive change through innovation.</p>
        </div>
      </div>
      <a id="FAQ"> <faqPage/></a>
        
    </div>
</template>

<script>
import top from "./Top"
import middle from "./Middle"
//import bottom from "./Bottom"
import experiencePage from './Experience'
import testimonySlider from "./TestimonySlider"
import faqPage from "./FAQ"
import teamPage from "./Team"
//import AppLink from "./AppLinks"

export default {
  name: "pageBody",
  components: {
      top,
      middle,
      //bottom,
      testimonySlider,
      faqPage,
      teamPage,
      experiencePage,
      //AppLink
      
  }
}
</script>

<style scoped>
@import "./assets/fonts/stylesheet.css";
  .pageBody {
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  .protocol-sect {
    background-image: url("./assets/bg_protocol.png");
    background-size: cover;
    background-position: center;
    margin: 3rem auto;
    text-align: center;
    font-family: 'HelveticaNeueCond';
  }
  .protocol-text {
    color: white;
    padding: 11.25rem 0;
  }
  .protocol-text p {
    margin-bottom: 1rem;
  }
  h1 {
    margin: 0;
    margin-bottom: 2rem;
    color:white;
  }
  p {
    width: 60%;
    margin: auto;
    font-size: 25px;
  }

  @media screen and (max-width: 750px) {
    p {
      width: 90%;
    }
  }
</style>