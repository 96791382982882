<template>
    <div class="bottom">
        <h1>Report issues quickly, and
        help keep everyone safe.</h1>
        <h2>If you see someone in need of help for any reason, 
        you will now have the power to report the issue
        and be part of the solution.</h2>
        <img id="mainImage" src="./assets/appscrn_help.png"/>
        <p > "Send Help" initiates a short form
        linked directly to security dispatch.</p>
        <AppLink/>
        <br/>
        
    </div>
</template>

<script>
import AppLink from './AppLinks'
export default {
  name: "bottom",
  components: {
      AppLink
  }
  
}
</script>

<style scoped>
    .bottom{
        outline: none;
        text-align: center;
        width:100%;
        }
    h2{
        font-size:2.1vw ;
        width:60%;
        margin-left:20%;
    }
    #mainImage{
    display: block;
     margin-left: auto;
     margin-right: auto;
     width:25%;
    }
    p{
        font-size:2.1vw ;
        font-family: 'HelveticaNeueThin';
        /*font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
        width:40%;
        margin-left:30%;
    }
    @media screen and (max-width:1024px){
        h1{
        font-size:3vmax ;
        
    }
    h2{
        font-size:2vmax ;
        
    }
    #mainImage{
            width:50%;
        }
    p{
            font-size:1.9vmax ;
        }
    }
</style>