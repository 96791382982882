<template>
  <div class="pageHeader">
    <a id="Home"></a>
    <div class="mainLogo">
        <img id="mainImage" alt="Buddy Logo" src="./assets/logo_main.png" />
    </div>
    <input class="menu-btn" type="checkbox" id="menu-btn" />
    <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
    <ul class="menu">
      <!--<li><a v-scroll-to="'#Home'"><button  class="tablinks">Home</button></a></li>-->
      <li><a v-scroll-to="'#StayConnected'"><button class="tablinks" >Stay Connected </button></a></li>
      <!--<li><a v-scroll-to="'#StaySafe'" ><button class="tablinks" >Stay Safe</button></a></li>-->
      <!--<li><a v-scroll-to="'#GetTheApp'"><button class="tablinks" >Get The App</button></a></li>-->
    <!-- <li> <a v-scroll-to="'#Contact'" ><button class="tablinks lastLink">Contact</button></a></li>-->
    <li><a v-scroll-to="'#Experience'"><button class="tablinks" >Event Experience </button></a></li>
    <li><a v-scroll-to="'#Team'"><button class="tablinks" >Team </button></a></li>
    <li><a v-scroll-to="'#Protocol'"><button class="tablinks" >Protocol </button></a></li>
    <li><a v-scroll-to="'#FAQ'"><button class="tablinks" >FAQ </button></a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pageHeader",
  components: { 
  },
}
</script>

<style scoped>
@import "./assets/fonts/stylesheet.css";
.pageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  width: 100%;
  z-index: 3;
}
.pageHeader ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: white;
}
.pageHeader li a {
  display: block;
  padding: 10px 20px;
  border-right: 1px solid #f4f4f4;
  text-decoration: none;
  cursor: pointer;
}
.pageHeader li a:hover {
  background-color: #f4f4f4;
}
.pageHeader li a button{
  font-size: 1.25rem;
  background-color:transparent;
  border:none;
  font-family: 'HelveticaNeueLight';
  outline: none;
  cursor: pointer;
}
.pageHeader .mainLogo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}
#mainImage{
  /* width:15vmax; */
  padding: 2vw;
}
.pageHeader .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
  padding:0;
  width:70%;
}
.pageHeader .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}
.pageHeader .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}
.pageHeader .menu-icon .navicon:before,
.pageHeader .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}
.pageHeader .menu-icon .navicon:before {
  top: 5px;
}
.pageHeader .menu-icon .navicon:after {
  top: -5px;
}
.pageHeader .menu-btn {
  display: none;
}
.pageHeader .menu-btn:checked ~ .menu {
  max-height: 240px;
}
.pageHeader .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}
.pageHeader .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}
.pageHeader .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}
.pageHeader .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.pageHeader .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}
@media (min-width: 962px) {

  .pageHeader li {
    float: left;
  }
  .pageHeader li a {
    padding: 1vw 2vw;
    
  }
  .pageHeader .menu {
    clear: none;
    float: right;
    max-height: none;
    padding:2vw;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .pageHeader .menu-icon {
    display: none;
  }
}

@media (max-width: 960px) {
  .pageHeader {
    display: block !important;
  }
}
  

</style>