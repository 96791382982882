<template>
    <div>
        <div class="top">
        <div class="largeAppLinks">
            <!--<h1>Stay Connected.<br/> Stay Safe.</h1>-->
            <h1>Build a culture of connectivity.</h1>
            <h2>Buddy is a peer to peer driven safety solution. Buddy provides a 
communication platform to a global community that can stay connected 
when cellular, data, or Wi-Fi network are limited.</h2>
            <div class="linkSet">
                <!--img class="links" src="./assets/appleIcon.png"/>
                <img class="links" src="./assets/googleIcon.png"/>-->
            </div>
            </div>
            <div class="phoneMain">
                <img width="100%" id="phoneImage" alt="Phone_Main" src="./assets/phone_main.png"/> 
            </div>
        </div>
        <div class="phoneMobile">
            <img width="100%" id="phoneImage" alt="Phone_Main" src="./assets/phone_main.png"/> 
        </div>
    </div>
</template>

<script>
export default {
  name: "top",
  components: {
  }
  
}
</script>

<style scoped>
@import "./assets/fonts/stylesheet.css";
.top{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background-size: cover;
    background-position: center;
    background-image: url("./assets/bg_feature.jpg") ;
    block-size: 40vw;
    height:40vw;
    /* font-family: 'HelveticaNeueCond'; */
    font-style: normal;
    /* font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; font-weight:600; font-stretch:condensed; */
}
.largeAppLinks{
    text-align: left;
    float:left;
    width:50%;
    margin: auto;
    /* font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; font-weight:600; font-stretch:condensed; */
}
h1{
    color:white;
    text-align: left;
}
h2{
    color:white;
    font-size: 1.5rem;
}
.links{
    padding-right:1vw;
    cursor: pointer;
    margin-bottom: 10px;
}

.linkSet {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.phoneMain{
    width:36%;
    height:103%;
    position: relative;
    top:-1.24vw;
    left:-7vw;
}

.phoneMobile {
    display: none;
}

@media screen and (max-width: 975px) {
    .top {
        block-size: auto;
        height: auto;
    }
    h2 {
        font-size: 1rem;
    }
    .linkSet {
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 1192px) {
    .top {
        block-size: auto;
        height: auto;
    }

    h1 {
        text-align: center !important;
    }

    .phoneMain {
        display: none !important;
    }

    .phoneMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .phoneMobile > img {
        width: 50% !important;
        min-width: 330px !important;
    }

    .largeAppLinks {
        width: 100%;
    }

    h2 {
        text-align: center;
        width: 60%;
        margin-left: 20%;
    }

    .linkSet {
        width: 100%;
        justify-content: center;
    }
}

#phoneImage{
    width:100%;
    height:100%;
}

</style>