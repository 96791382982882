<template>
    <div class="experiencePage" >
       <h1>Crowd Management Platform</h1>
       <h2>BUDDY is transforming the operations model for high-density venues and events by delivering real-time crowd intelligence to improve safety and crowd flow, enhance the fan experience, and increase opportunities for brand sponsorship through innovative data capture.</h2>
       <p>Inquire below about more info.</p>
       <button @click="open()" class="read-more">More Info</button>
       <moreInfoForm v-if="showModal" @close="exit" />
    </div>
</template>


<script>
import moreInfoForm from "./MoreInfoForm"

export default {
  name: 'teamPage',
  components:{
    moreInfoForm
  },
  
  data:function() {
    return {
      showModal: false,
    }
  },
  methods:{
    exit:function() {
      this.showModal = false;
    },
    open:function() {
      this.showModal = true
    }
  }
}

</script>

<style scoped>
@import "./assets/fonts/stylesheet.css";
.experiencePage {
  background-size: cover;
  background-position: center;
  background-image:url("./assets/bg_venueevent.png");
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  text-align: center;
  padding:5rem 0;
  color:white;
  margin:3rem auto;
  margin-bottom: 0;
  /* font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; font-weight:600; font-stretch:condensed; */
}
h1 {
  color:white;
  margin-bottom: 0;
  font-family: 'HelveticaNeueCond';
  /* font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; font-weight:600; font-stretch:condensed; */
}
h2 {
  font-size: 30px;
  margin-bottom: 1rem;
  width: 60%;
}

@media screen and (max-width: 775px) {
  h2 {
    width: 95%;
  }
}

p {
  margin: .5rem 0;
  font-size: 18px;
  /* font-family: "HelveticaNeue", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; */
  /* font-weight:100; */
}
.read-more{
  cursor: pointer;
  color:#9400b7;
  background-color: white;
  border: none;
  border-radius: 15px;
  font-size: 22px;
  padding: .5rem 3rem;
  font-family: 'HelveticaNeueCond';
  /* font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; */
  /* font-weight: 300;
  font-stretch: condensed; */
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.member-title{
  align-content: center;
}
</style>